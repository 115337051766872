<template>
  <tp-modal
    v-if="saleReceipt.id"
    max-width="1240px"
    name="modal-sale-receipts"
    width="90%"
  >
    <div id="modal-sale-receipt" slot-scope="props">
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          Hoá đơn bán #{{ saleReceipt.code }}
        </v-toolbar-title>

        <v-chip
          :color="
            saleReceipt.site === LIST_MODEL_SITES.GOODSPACE.id
              ? 'teal lighten-5 teal--text teal--lighten-1'
              : 'blue lighten-5 blue--text text--darken-4'
          "
          class="text-body-1 font-weight-bold px-2 py-2 ml-2"
          label
          small
        >
          {{
            saleReceipt.site
              ? LIST_MODEL_SITES[saleReceipt.site].text
              : LIST_MODEL_SITES.THINKPRO.text
          }}
        </v-chip>

        <v-spacer></v-spacer>

        <v-btn
          v-if="
            isPermissionCancel &&
              ((!props.payload && saleReceipt.status !== -1) ||
                (props.payload &&
                  !props.payload.mode !== 'VIEW' &&
                  saleReceipt.status !== -1))
          "
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-3"
          depressed
          @click="cancelSaleReceipt(props.payload.index)"
        >
          Hủy
        </v-btn>

        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          depressed
          text
          @click="goToSalePage"
        >
          Chỉnh sửa
        </v-btn>

        <v-btn
          v-if="saleReceipt.status !== SALE_RECEIPT_STATUS.CANCEL.value"
          :to="{
            name: 'print_sale-receipt',
            params: {
              saleReceiptId: saleReceipt.id
            }
          }"
          class="mr-2"
          color="primary"
          icon
          target="_blank"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs ref="tabs" v-model="activeTab" height="40px">
            <v-tab
              v-for="item in tabItems"
              :key="item.id"
              class="font-weight-bold"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="activeTab" class="modal-body-scroll">
        <v-tab-item
          v-for="item in tabItems"
          :key="item.id"
          class="tab-item grey lighten-3 pa-5"
        >
          <component :is="`tab-${item.id}`" ref="tabItems"></component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </tp-modal>
</template>

<script>
import TabDetail from "./components/TabDetail/index";
import TabParticipants from "./components/TabParticipants";
import TabReturnHistory from "./components/TabReturnHistory.vue";
import TabFeedback from "./components/TabFeedback.vue";
import { LIST_MODEL_SITES } from "@/core/constant";
import { compareTwoDates } from "@/core/utils";
import { SALE_RECEIPT_STATUS } from "@/modules/Sale/pages/SaleReceipt/constants";

export default {
  components: {
    TabDetail,
    TabParticipants,
    TabReturnHistory,
    TabFeedback
  },

  data() {
    return {
      activeTab: null,
      LIST_MODEL_SITES,
      SALE_RECEIPT_STATUS
    };
  },

  computed: {
    tabItems() {
      const items = [
        { id: "detail", text: "Thông tin", show: true },
        { id: "participants", text: "Phục vụ", show: true },
        {
          id: "return-history",
          text: "Lịch sử trả hàng",
          show: this.saleReceipt.return_goods_histories.length > 0
        },
        { id: "feedback", text: "Feedback", show: true }
      ];

      return items.filter(item => item.show);
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    isPermissionCancel() {
      const { permissions, role } = this.user;

      if (role.toLowerCase() === "nhóm quyền quản lý cửa hàng") {
        return compareTwoDates(this.saleReceipt.created_at, new Date()) === 1;
      }

      return permissions.includes("sale_receipts.cancel");
    }
  },

  methods: {
    cancelSaleReceipt(index) {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy hóa đơn <strong>${this.saleReceipt.code}</strong></span>`,
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch("SALE_RECEIPT/cancelSaleReceipt", {
              id: this.saleReceipt.id,
              index: index,
              cancelReason: cancelReason
            });

            // Alert
            if (
              this.saleReceiptStatusRequest.value ===
              "success-cancelSaleReceipt"
            ) {
              // Alert
              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message: "Đã hủy hóa đơn"
                }
              });
            } else if (
              this.saleReceiptStatusRequest.value === "error-cancelSaleReceipt"
            ) {
              // Alert error
              this.$modal.show({
                name: "modal-alert",
                payload: {
                  title: `<span class="red--text text--accent-2">${this.saleReceiptStatusRequest.message}</span>`,
                  cancelBtnText: "OK"
                }
              });
            }

            this.$modal.hide({ name: "modal-sale-receipts" });
          }
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "modal-sale-receipts" });
      this.activeTab = null;
      this.$refs.tabs.callSlider();
    },

    async goToSalePage() {
      const foundSaleTab = this.saleTabs.find(
        item => item.id === this.saleReceipt.id
      );

      if (!foundSaleTab) {
        let localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

        await this.$store.dispatch("SALE/addSaleTabItem", {
          ...this.saleReceipt,
          branch_id: this.saleReceipt.branch.id,
          khachhang_id: this.saleReceipt.khachhang.id
        });

        // Set active tab
        await this.$store.dispatch(
          "SALE/setSaleTabActiveIndex",
          localSaleTabs ? localSaleTabs.length : 0
        );
      } else {
        await this.$store.dispatch("SALE/updateSaleTabItem", {
          ...this.saleReceipt,
          branch_id: this.saleReceipt.branch.id,
          khachhang_id: this.saleReceipt.khachhang.id
        });
      }

      this.closeModal();
      // Push route
      await this.$router.push({
        name: "sale",
        query: {
          type: "SALE_RECEIPT",
          id: this.saleReceipt.id
        }
      });
    }
  }
};
</script>
