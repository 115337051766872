<template>
  <v-card class="px-3 py-2" flat>
    <div class="d-flex" style="gap: 20px;">
      <div class="w-full">
        <div class="font-weight-bold mb-2">Tên, địa chỉ</div>

        <v-text-field
          v-model="name"
          background-color="white"
          class="text-body-1"
          dense
          filled
          outlined
          placeholder="Tên và địa chỉ"
          single-line
        ></v-text-field>
      </div>

      <div class="w-full">
        <div class="font-weight-bold mb-2">Nội dung</div>

        <v-text-field
          v-model="content"
          background-color="white"
          class="text-body-1"
          dense
          filled
          outlined
          placeholder="Nhập nội dung"
          single-line
        ></v-text-field>
      </div>
    </div>

    <div class="font-weight-bold mb-2">Bộ ảnh</div>

    <div class="ml-1 ">
      <draggable
        v-model="images"
        class="d-flex flex-wrap gap-3"
        draggable=".item"
        v-bind="dragOptions"
      >
        <div slot="header" class="f-flex flex-col">
          <input
            type="file"
            ref="imagesInput"
            accept="image/*"
            style="display: none"
            multiple
            @change="handleFileUpload"
          />

          <div class="gallery-add rounded" @click="$refs.imagesInput.click()">
            <v-btn :loading="loading" icon>
              <v-icon class="grey--text text--lighten-1" large>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>

          <div
            class="red--text text--accent-2 my-1 text-body-2"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </div>
        </div>

        <v-hover
          v-for="(item, index) in images"
          :key="index"
          v-slot:default="{ hover }"
          class="item cursor-move"
        >
          <div class="gallery-item">
            <v-img
              :aspect-ratio="1"
              :src="item && item.slice(0, 5) !== 'blob:' ? item : undefined"
              class="grey lighten-3 rounded tp-boder-thin"
              height="120px"
              width="120px"
            >
              <v-fade-transition>
                <v-row
                  v-if="hover"
                  align="center"
                  class="fill-height grey darken-4 ma-0"
                  justify="center"
                  style="opacity: 0.65"
                >
                  <v-btn color="white" icon @click="previewImage(item)">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>

                  <v-btn color="white" icon @click="removeImage(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-fade-transition>
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </v-hover>
      </draggable>

      <modal-preview-image :url="selectedImage"></modal-preview-image>
    </div>

    <v-btn
      class="mt-4"
      color="primary"
      dark
      depressed
      :loading="loadingUpdate"
      @click="handleUpdateFeedback"
    >
      Lưu
    </v-btn>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import ModalPreviewImage from "@/core/components/modals/ModalProduct/components/ModalPreviewImage.vue";
import MediaService from "@/core/service/media.service";
import SaleReceiptService from "@/modules/Transaction/service/sale-receipt.service";

export default {
  components: {
    draggable,
    ModalPreviewImage
  },

  data() {
    return {
      apiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(","),
      name: "",
      content: "",
      images: [],
      selectedImage: "",
      maxSize: 1 * 1024 * 1024, // 50MB,
      errorMessage: "",
      loading: false,
      loadingUpdate: false
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    }
  },

  methods: {
    previewImage(url) {
      this.selectedImage = url;
      this.$modal.show({ name: "modal-preview-image" });
    },

    removeImage(url) {
      console.log("remove image", url);
      this.images = this.images.filter(image => image !== url);
    },

    async handleFileUpload(event) {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const isImageOverSize = Array.from(files).some(
        file => file.size > this.maxSize
      );

      if (isImageOverSize) {
        this.errorMessage = "Kích thước mỗi ảnh không được vượt quá 50MB.";
        return;
      } else {
        this.errorMessage = "";
        const formData = new FormData();
        formData.append("folder", "order");

        for (let i = 0; i < files.length; i++) {
          formData.append("files[]", files[i]);
        }

        this.$refs.imagesInput.value = null;

        try {
          this.loading = true;
          const response = await MediaService.uploadMultipleImages(formData);
          this.images = [...this.images, ...response.data];
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },

    async handleUpdateFeedback() {
      try {
        this.loadingUpdate = true;
        await SaleReceiptService.updateSaleReceiptFeedback({
          hoa_don_ban_id: this.saleReceipt.id,
          customer_info: this.name,
          content: this.content,
          images: this.images
        });

        await this.$store.dispatch(
          "SALE_RECEIPT/getSaleReceiptById",
          this.saleReceipt.id
        );

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật feedback thành công"
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUpdate = false;
      }
    }
  },

  watch: {
    "saleReceipt.feedback": {
      handler(value) {
        if (!value) {
          this.name = `${this.saleReceipt.khachhang.name}${
            this.saleReceipt.khachhang.address[0] &&
            this.saleReceipt.khachhang.address[0].tinh_thanhpho
              ? `, ${this.saleReceipt.khachhang.address[0].tinh_thanhpho}`
              : ""
          }`;
          this.content = "";
          this.images = [];
          return;
        }

        this.name = value.customer_info;
        this.content = value.content;
        this.images = value.images;
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-move {
  cursor: move;
}

.ghost {
  opacity: 0.5;
  background: #ccc;
}

.gap-3 {
  gap: 12px;
}

.gallery {
  &-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 120px !important;
    transition: all 0.4s ease;
    width: 120px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }

  &-item {
    display: flex;

    .v-image {
      border: 1px solid #e5e5e5;
    }
  }
}
</style>
